import React, {useEffect, useState} from "react";
import { useControles } from "../../../context/controles.context";
import { useNavigate } from "react-router-dom";
import { useGame } from "../../../context/games.context";
import { RadarLoading } from "./RadarLoading";

const ItemGame = ({urlIcone, titulo, roletaId, tipoMesa, linkJogo, integracao, jogo, statusResultado, jogadaId = null, texto = null}) => {
    const { isMobile } = useControles()
    const {setActiveGame} = useGame()
    const navigate = useNavigate()
    const [isResult, setIsResult] = useState(false)
    const [status, setStatus] = useState(statusResultado)

    const handleClick = () => {
        navigate(`/SalaDeJogos/${roletaId}/${integracao}/${jogo}`)
        setActiveGame({
            jogo: titulo,
            urlIcone: urlIcone,
            roletaId: roletaId,
            tipoMesa: tipoMesa,
            linkJogo: linkJogo,
            integracao: integracao,
            jogo: jogo,
            jogadaId: jogadaId,
            texto: texto,
            statusResultado: status
        })
    }

    useEffect(() => {
        setStatus(statusResultado)
    }, [statusResultado])

    useEffect(() => {
        // console.log(titulo, statusResultado, " => status", statusResultado == 3 ? 'green' : statusResultado == 4 && 'red')
        if(statusResultado == 3 || statusResultado == 4){
            const timeout = setTimeout(() => {
                setStatus(0)
            }, 5000)
            return () => clearTimeout(timeout)
        }
    }, [statusResultado])



     return (
        <>
            <div style={{width: '100%', height: '100%', 
                background: 
                    status == 1 || status == 0 ? '#00000055' :
                    status == 2 ? '#ffc400' : 
                    status == 3 ? '#00ff00' :
                    status == 4 && '#f20202', 
                
                color: status != 2 ? 'white' : 'black', display: 'flex', flexDirection: 'column', borderRadius: '8px', fontSize: 20, alignItems: 'center'}}>
                {/* <span style={{textAlign: 'center', width: '100%', padding: 4}}>
                    {status == 1 ? "Analisando" : status == 2 ? "Confirmada" : status == 3 ? "Green" : status == 4 && "Red"}
                </span> */}
                {status == 1 ? 
                    <RadarLoading text={"Analisando"} status={1}/> 
                : status == 0 ?
                    <div style={{width: '100%'}}>
                        <RadarLoading text={"Hackeando mesas"}/> 
                    </div>
                 :
                 <span style={{textAlign: 'center', width: '100%', padding: 4, height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {status == 2 ? "Confirmada" :
                        status == 3 ? "Green" : 
                        status == 4 && "Red"}
                </span>
                }
               
                <img 
                src={urlIcone} 
                style={{width: '100%', height: '100%', borderRadius: '8px', cursor: 'pointer'}}
                onClick={handleClick}
                /> 
            </div>
           
            
        </>
    )
}

export default ItemGame

