import React, { useEffect, useState } from "react";
import Router from "./routes/routing";
import { WhitelabelProvider, useWhitelabel } from "./context/whitelabel.context";
import { GamesProvider } from "./context/games.context";
import { AuthProvider } from "./context/auth.context";
import { ControlesProvider } from "./context/controles.context";
import { ClienteProvider } from "./context/cliente.context";
import { socket } from "./services/socketio";

function App() {
  
  return (
    <>
    
      <WhitelabelProvider>
        <ClienteProvider>
          <ControlesProvider>
            <AuthProvider>
              <GamesProvider>
                <>
                  <Router/>
                </>
              </GamesProvider>
            </AuthProvider>
          </ControlesProvider>
        </ClienteProvider>
      </WhitelabelProvider>
    </>
  );
}

export default App;
