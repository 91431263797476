import * as React from 'react';
import {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { PrimaryButton, PrimaryLabel, PrimaryTextInput, SecondaryButton } from '../../globalComponents';
import { useCliente } from '../../context/cliente.context';
import { useControles } from '../../context/controles.context';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: '90vw',
  height: '80%',
  bgcolor: 'var(--Primary-background)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalTabela() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {isMobile} = useControles()

  const {GetControleFinanceiro, InsertControleFinanceiro, UpdateControleFinanceiro} = useCliente()

  const [isEditing, setIsEditing] = useState()

    const [entradas, setEntradas] = useState([
        {
            id: 1,
            jogo: '',
            data: '',
            valor: 0
            
        }
    ])
    
    const fetchEntradas = async () => {
        return await GetControleFinanceiro(JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder'))._id).then((response) => {
            if(response){
                console.log(JSON.parse(response.entrada))
                setEntradas(JSON.parse(response.entrada))
                setIsEditing(true)
            }
        }).catch((err) => {
            setEntradas([{
                id: 1,
                jogo: '',
                data: '',
                valor: 0
            }])
            setIsEditing(false)
        })
    }

    

    useEffect(() => {
        fetchEntradas()
        const entradas = localStorage.getItem('entradas')
        if (entradas) {
            setEntradas(JSON.parse(entradas))
        }
    }, [open]) 

    const handleAddItemOnList = () => {
        const newItem = {
            id: entradas.length + 1,
            jogo: '',
            data: '',
            valor: ''
        }
        setEntradas([...entradas, newItem])
    }

    const saveListOnLocalstorage = () => {
        localStorage.setItem('entradas', JSON.stringify(entradas))
        setOpen(false)
    }  

    const saveListOnDatabase = async () => {
        return await InsertControleFinanceiro({userId: JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder'))._id, entrada: JSON.stringify(entradas)})
    }
  
    const updateListOnDatabase = async () => {
        return await UpdateControleFinanceiro({userId: JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder'))._id, entrada: JSON.stringify(entradas)})
    }

  return (
    <div>
      <PrimaryButton style={{padding: 10}} onClick={handleOpen}>Minhas entradas</PrimaryButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{color: 'var(--Primary-text)', maxWidth: '90vw'}}>
            Acompanhe suas entradas
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{display: 'flex', justifyContent: 'space-between', textAlign: 'left',}}>
                <PrimaryLabel style={{color: 'black', width: '100%'}}>
                    Jogo
                </PrimaryLabel>
                <PrimaryLabel style={{width: '100%', textAlign: 'left'}}>
                    Data
                </PrimaryLabel>
                <PrimaryLabel style={{width: '100%', textAlign: 'left'}}>
                    Valor
                </PrimaryLabel>
            </div>
            
            {entradas && entradas.map((entrada, indice) => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} key={entrada.id}>
                            <PrimaryTextInput 
                                label="Jogo" 
                                value={entrada.jogo} 
                                style={{width: isMobile && '120px'}}
                                onChange={(e) => {
                                    const valorAtualizado = e.target.value;

                                    // Cria uma nova cópia do array e atualiza o valor para o índice específico
                                    const entradasAtualizadas = entradas.map((item, idx) => {
                                        if (idx === indice) {
                                            return { ...item, jogo: valorAtualizado };
                                        }
                                        return item;
                                    });

                                    // Atualiza o estado com o novo array
                                    setEntradas(entradasAtualizadas);
                                }}
                            />
                            <PrimaryTextInput label="Data" value={entrada.data} style={{width: isMobile && '120px'}} onChange={(e) => {
                                const valorAtualizado = e.target.value;

                                // Cria uma nova cópia do array e atualiza o valor para o índice específico
                                const entradasAtualizadas = entradas.map((item, idx) => {
                                    if (idx === indice) {
                                        return { ...item, data: valorAtualizado };
                                    }
                                    return item;
                                });

                                // Atualiza o estado com o novo array
                                setEntradas(entradasAtualizadas);
                            
                            }}/>
                            <PrimaryTextInput label="Valor" value={entrada.valor} style={{width: isMobile && '120px'}} onChange={(e)=>{
                                const valorAtualizado = e.target.value;

                                // Cria uma nova cópia do array e atualiza o valor para o índice específico
                                const entradasAtualizadas = entradas.map((item, idx) => {
                                    if (idx === indice) {
                                        return { ...item, valor: valorAtualizado };
                                    }
                                    return item;
                                });

                                // Atualiza o estado com o novo array
                                setEntradas(entradasAtualizadas);
                            }}/>
                        </div>
                    );
                })
            }

          </Typography>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '10px 0', gap: 10, position: 'absolute', bottom: 10, width: '100%', left: 0}}>
            <SecondaryButton style={{padding: 10, width: '33%'}} onClick={() => setOpen(false)}>
                Fechar
            </SecondaryButton>

            <Button onClick={handleAddItemOnList} style={{background: 'var(--Primary-color)'}} variant="contained">+</Button>

            <PrimaryButton style={{padding: 10, width: '33%'}} onClick={async () => {
                if(isEditing){
                    await updateListOnDatabase().then(() => {
                        setOpen(false)
                    })
                } else {
                    await saveListOnDatabase().then(() => {
                        setOpen(false)
                    })
                }
                
            }}>
                Salvar
            </PrimaryButton>
          </div>
          
        </Box>
      </Modal>
    </div>
  );
}