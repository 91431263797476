import React, { useEffect, useState } from "react";
import { useGame } from "../../../context/games.context";
import ItemGame from "../ItemGame";
import { useControles } from "../../../context/controles.context";
import { Icon } from '@iconify/react';
import { socket } from "../../../services/socketio";
import { combinarListas } from "./combinarLista";
import { RadarLoading } from "../ItemGame/RadarLoading";
import LoadingMain from "../../Loadings/LoadingMain";
import {HackingLoading} from "../../Loadings/HackingLoading";
import { SearchingGames } from "../../Loadings/SearchingGames";

const CategoriaGame = ({categoria}) => {
    const { GetJogos, listaJogos, setListaJogos, GetStatusResultado } = useGame()
    const {isMobile} = useControles()
    const [titulo, setTitulo] = useState()
    const [icone, setIcone] = useState()
    const [listaJuntinha, setListaJuntinha] = useState()
    const [contemStatus, setContemStatus] = useState(false)

    const [listaStatus, setListaStatus] = useState([])

    const fetchStatus = async () => {
        const response = await GetStatusResultado()
        setListaStatus(response.data)
    }

    useEffect(() => {
        console.log("ListaStautus: ", listaStatus)
        fetchStatus()
        socket.connect();
        console.log("Conectado")
    }, [])

    


    useEffect(() => {
        socket.on('database_update', (data) => {
          setListaStatus(data.recordsets[0])
        })
      }, []);
  

      useEffect(function juntandoAsListas (){
        const listaJunta = combinarListas(listaJogos, listaStatus)
        console.log("Lista atualizada")
        console.log(listaJunta)
        for(let i = 0; i < listaJunta.length; i++){
            if(listaJunta[i].StatusResultado){
                setContemStatus(true)
                break
            }
        }
        setListaJogos(listaJunta)
      }, [listaStatus])

    //populares - 0
    //roleta - 1
    //cassino - 2
    //cassino ao vivo - 3

    useEffect(()=>{
        GetJogos()
        if(categoria == 0){
            setTitulo('Populares')
            setIcone('tabler:star-filled')
        } else if(categoria == 1){
            setTitulo('Roleta')
            setIcone('fad:random-2dice')
        } else if(categoria == 2){
            setTitulo('Cassino')
            setIcone('teenyicons:joystick-solid')
        } else if(categoria == 3){
            setTitulo('Cassino ao vivo')
            setIcone('mdi:casino-outline')
        }
    }, [])

    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column', gap: 20, width: '100%'}}>
            <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'var(--Primary-color)', gap: 10, fontSize: '24px'}}>
                <Icon icon={icone} className="icon" />
                <h1 style={{color: 'var(--Primary-text)', fontSize: '20px'}}>{titulo}</h1>
            </span>
            
            
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'end', flexWrap: 'wrap', gap: 10, justifyContent: !isMobile ? 'flex-start' : 'flex-start'}}>
                {!contemStatus ?
                    // <RadarLoading text={"Carregando mesas..."} color={"white"}/>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <SearchingGames/>
                    </div> :
                    listaJogos && listaJogos.map(jogo => {
                            if(categoria == 0){
                                if(jogo.popularBool){
                                    return (
                                        <div style={{width: isMobile ? '50%' : '100%', maxWidth: !isMobile ? '150px': '48%'}} key={jogo.jogadaId}> 
                                            <ItemGame urlIcone={jogo.iconUrl} titulo={jogo.nomeJogo} roletaId={jogo.roletaId} tipoMesa={jogo.tipoMesa} linkJogo={jogo.linkJogo} integracao={jogo.integracao} jogo={jogo.jogo} statusResultado={jogo.StatusResultado} jogadaId={jogo.jogadaId} texto={jogo.texto}/>
                                        </div>
                                    )
                                }
                            }

                        if(categoria != 0){
                            if(jogo.tipoMesa == categoria){
                                return (
                                    <div style={{width: isMobile ? '50%' : '100%', maxWidth: !isMobile ? '150px': '48%'}} key={jogo.jogadaId}>
                                        <ItemGame urlIcone={jogo.iconUrl} titulo={jogo.nomeJogo} roletaId={jogo.roletaId} tipoMesa={jogo.tipoMesa} linkJogo={jogo.linkJogo} integracao={jogo.integracao} jogo={jogo.jogo}/>
                                    </div>
                                )
                            }
                        }
                    })}
                
            </div>
        </div>
        </>
    )
}

export default CategoriaGame