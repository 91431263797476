import React, { useEffect, useState } from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryButton, SecondaryButton, Separator, BackgroundWrapper } from "../../globalComponents";
import Header from "../Header"
import { Container, DivPai } from "./styles";

import { useNavigate } from 'react-router-dom';
import SlideShow from "../SlideShow";
import { useWhitelabel } from "../../context/whitelabel.context";
import News from "./News";
import ExampleNews from '../../assets/exemploNew.png'
import ItemGame from "../CardGames/ItemGame";
import IconeJogo from '../../assets/imgCardGame.png'
import { useCliente } from "../../context/cliente.context";
import CategoriaGame from "../CardGames/CategoriaGame";
import { Icon } from '@iconify/react';
import axios from 'axios'
import {io} from "socket.io-client"
import { socket } from "../../services/socketio";




  
  
//   socket.on('database_update', data => {
//     console.log('Dados recebidos:', data);
//   });
  
//   socket.on('disconnect', () => {
//     console.log('Desconectado do servidor');
//   });


const CardMenu = () => {


    const {isMobile, setSideBarStatus} = useControles()
    const {listaSlideShow, listaNews, GetBanners} = useCliente()
    const [listaAux, setListaAux] = useState()
    const navigate = useNavigate()
    const {listaGanhadores, GetListaGanhadores, GetListaRank, rankingBool, nomesBool, credits, setcredits} = useWhitelabel()
    const [rank, setRank] = useState([])
    const [iniciarAnimacao, setIniciarAnimacao] = useState(false)

    const [listaNomes, setListaNomes] = useState([])

   
    const fetchData = async () => {
        const response = await GetListaGanhadores()
        setListaNomes(response.data)
        
    }

    const fetchRank = async () => {
        const response = await GetListaRank()
        //console.log(response.data, " ranking")
        setRank(response.data)
    }

    const fetchSaldo = async () => {
        await axios.get(`https://proxy.lineragamessub.com.br/Betfive/GetSaldoAtual?token=${localStorage.getItem('tokenBetfive')}`).then((response) => {
            console.log(response.data.credits, " => metodo get credits do header")
            localStorage.setItem('saldo', response.data.credits)
            setcredits(response.data.credits)
            
        }).catch((err) => {
            console.log(err, " => metodo get credits do header")
        })
    }

    // const [instanceSocket] = useState(socket())
    
    

    useEffect(()=>{
        //fetchSaldo()
        GetBanners()
        fetchRank()
        setSideBarStatus(true)
    }, [])

    useEffect(()=>{
        fetchData()
    }, [listaSlideShow])

    useEffect(()=>{
        const interval = setTimeout(()=>{
            setIniciarAnimacao(true)
        }, 200)
        //....
        return () => clearTimeout(interval)
    }, [listaGanhadores])

    

    return (
        <>
            {isMobile ? 
                <>
                    <Container>
                    {nomesBool == 1 && 
                    
                    
                        <div className="scrolling-container" style={{ width: '100%', background: 'var(--Secondary-Background)', height: '20px', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                            <div className={iniciarAnimacao && "scrolling-text"}>
                                {listaNomes.map((nome, index) => {
                                const parts = nome.split(/ ganhou | no /);
                                const name = parts[0];
                                const valor = parts[1];
                                const jogo = parts[2];

                                return (
                                    <span key={index} style={{ color: 'var(--Primary-text)', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap',
                                    }}>
                                        <Icon icon="jam:coin" style={{ color: '#EFBA18', fontSize: '20px', marginRight: '-5px' }} />
                                        <span style={{ fontWeight: 'bold', marginRight: '-5px' }}>{name}</span>
                                        ganhou <span style={{ color: '#EFBA18', fontWeight: 'bold', marginLeft: '-5px' }}>{valor} no {jogo}</span>
                                    </span>

                                )
                                })}
                                </div>                   
                            </div>
                        }
                            {listaSlideShow.length > 0 && 
                                <div style={{width: '95%', height: '50%', padding: '30px 0'}}>
                                    <SlideShow listaImagens={listaSlideShow}/>
                                </div>
                            }
                            
                            {rankingBool == 1 && 
                                <>
                                     <div className="ranking" style={{width: '95vw', height: '100%', background: 'var(--Secondary-Background)', borderRadius: 20, padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, paddingBottom: 20, marginBottom: 20}}>
                                        <h2 style={{color: 'var(--Primary-text)', textAlign: 'center', width: '100%', fontSize: '18px', paddingTop: 10, paddingBottom: 10}}>Ranking dos Melhores Jogos</h2>
                                        {rank.map((r, i)=>{
                                            return (
                                                <div style={{display: 'flex', alignItems: 'center', width: '90%', justifyContent: 'space-around', color: 'var(--Primary-text)', textAlign: 'left', padding: 10, background: 'var(--Primary-background)', border: '1px solid var(--Primary-color)', borderRadius: 10, height: 64, boxShadow: i < 3 && '0px 0px 9px 0px var(--Primary-color)', gap: 15}}>
                                                    <span style={{fontWeight: 'bold', fontSize: '24px'}}>{i+1}</span>
                                                    <span style={{textAlign: 'left', width: '150px', fontWeight: '500', fontSize: '17px'}}>{r.nomeJogo}</span>
                                                    <span style={{display: 'flex', alignItems: 'center', gap: 10, textAlign: 'left', width: '70px', fontWeight: 'bold'}}>
                                                        <div style={{background: 'green', width: 15, height: 15, borderRadius: 50}}></div>
                                                        {r.green}
                                                    </span>
                                                    <span style={{display: 'flex', alignItems: 'center', gap: 10, textAlign: 'left', width: '70px'}}>
                                                        <div style={{background: 'red', width: 15, height: 15, borderRadius: '50%'}}></div>
                                                        {r.red}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>      
                                </>
                            }
                         
                                

                                <div style={{width: '95%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                                    <CategoriaGame categoria={0}/>
                                <div>

                                </div>
                            </div>
                    </Container>
                    <BackgroundWrapper>
                        <div className="bgImage">
                            
                        </div>
                    </BackgroundWrapper>
                </> : 
                <>
                    <Container>
                        <DivPai>
                            <div style={{width: '80%', padding: '30px 0'}}>
                                <SlideShow listaImagens={listaSlideShow}/>
                            </div>

                            {/* <div style={{width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '40px 0', gap: 20}}>
                                <div>
                                    <News titulo={listaNews[0].titulo} subtitulo={listaNews[0].subtitulo} urlImg={listaNews[0].urlImg} url={listaNews[0].url}/>
                                </div>

                                <div>
                                    <News titulo={listaNews[0].titulo} subtitulo={listaNews[0].subtitulo} urlImg={listaNews[0].urlImg} url={listaNews[0].url}/>
                                </div>
                            </div> */}

                                <div style={{width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                                    <div className="jogos">
                                        <CategoriaGame categoria={0}/>
                                    </div>
                                <div>
                                </div>
                            </div>
                        </DivPai>
                    <BackgroundWrapper>
                        <div className="bgImage">
                        </div>
                    </BackgroundWrapper>
                    </Container>
                </>
            }

            <style>
                {`
                .scrolling-container {
                    display: flex;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                  
                  .scrolling-container span {
                    display: inline-block;
                    padding: 0 10px; /* Espaçamento entre os elementos */
                    animation: scrollText 200s ease infinite; 
                    line-height: 0px;
                  }
                  

                  .scroll-container {
                    width: 100%;
                    background: var(--Secondary-Background);
                    height: 20px;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                  }
                  
                  .scrolling-text {
                    will-change: transform;
                    color: var(--Primary-text);
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    animation: scrollLeft 500s ease;
                    -webkit-animation: scrollLeft 500s ease;
                    
                  }
                  
                  .hidden-text {
                    display: none;
                  }
                  
                  @keyframes scrollLeft {
                    0%{
                        -webkit-transform: translateX(0%);
                        transform: translateX(0%);
                        
                    }
                    

                    100% {
                        -webkit-transform: translateX(100%);
                        transform: translateX(100%);
                    }
                  } 
                  `}
            </style>
        </>
    )
}

export default CardMenu