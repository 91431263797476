import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useControles } from "../../../context/controles.context";
import { Deposit } from "../../../services/betfive";
import { PrimaryButton, PrimaryTextInput } from "../../../globalComponents";
import QRCode from 'qrcode.react';

import axios from 'axios'
import { MiniLoading } from "../../MiniLoading";
import LoadingMain from "../../Loadings/LoadingMain";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '15px 5px',
    maxWidth: '600px',
    width: '90%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--Secondary-Background)',
    borderRadius: '8px',
    gap: 2,
    
  };

const ModalDeposit = () => {
    const {open, setOpen, tituloModal, urlModal, openDeposit, setopenDeposit, setloadingDeposit, isMobile} = useControles();

    const handleOpen = () => setopenDeposit(true);
    const handleClose = () => setopenDeposit(false);

    const [objDeposit, setobjDeposit] = useState()
    const [valor, setvalor] = useState()
    const [errorDeposit, setErrorDeposit] = useState(false)

    const [imagemQRCODE, setimagemQRCODE] = useState()

    const [isLoading, setIsLoading] = useState(false)

    const fetchDeposit = async (valor) => {
        setIsLoading(true)
        await Deposit(valor).then((res) =>{
            setobjDeposit(res.data)
            setErrorDeposit(false)
            console.log(res.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.log(err.response.data)
            if(err.response.data.code == 3232){
                setErrorDeposit('Número de depositos diario excedido.')
            } else if (err.response.data.code == 3004){
                setErrorDeposit('Digite o valor do deposito.')
            }
            
        })
        
    }

    useEffect(() => {
        const fetchData = async () => {
            if(objDeposit){
                const response = await axios.get(objDeposit.info.qr_code_image)
                console.log(response, 'QR CODE')
                const codigo64 = response.data
                const imageUrl = `data:image/png;base64,${codigo64}`;
                setimagemQRCODE(imageUrl)
            }
        }
        
        fetchData()
    }, [objDeposit]);

    const copyQrCode = () => {
        // Verifica se o navegador suporta a API de Clipboard
        if (navigator.clipboard) {
            // Copia o valor de objDeposit.info.qr_code para a área de transferência
            navigator.clipboard.writeText(objDeposit.info.qr_code)
                .then(() => {
                    console.log('Texto copiado com sucesso!');
                })
                .catch(err => {
                    console.error('Erro ao copiar texto: ', err);
                });
        } else {
            // Fallback para navegadores que não suportam a API de Clipboard
            const textArea = document.createElement('textarea');
            textArea.value = objDeposit.info.qr_code;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                console.log('Texto copiado com sucesso!');
            } catch (err) {
                console.error('Erro ao copiar texto: ', err);
            }
            document.body.removeChild(textArea);
        }
    }
  
    return (
      <div style={{position: 'relative'}}>
        
        <Modal
          open={openDeposit}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h2 style={{color: 'var(--Primary-text)', fontSize: '32px'}}>Depositar</h2>

            <div style={{display: 'flex', flexDirection: !isMobile ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', gap: !isMobile ? 80 : 30, paddingTop: 10}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                    <span style={{color: 'white'}}>
                        Digite o valor que deseja depositar:
                    </span>
                    <PrimaryTextInput type="number" placeholder="0,00" value={valor} onChange={(e) => setvalor(e.target.value)} />
                    <PrimaryButton style={{padding: 10}} onClick={() => {
                        fetchDeposit(valor)
                    }}>
                        Depositar
                    </PrimaryButton>
                    
                </div>

                {objDeposit && imagemQRCODE &&

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 20}}>
                        <img src={imagemQRCODE} style={{width: '150px', height: '150px'}}/>

                        {/* <QRCode value={imagemQRCODE}/> */}

                        <PrimaryButton style={{padding: '10px 20px'}} onClick={() => {
                            copyQrCode()
                        }}>
                            Copiar Codigo
                        </PrimaryButton>

                        <span style={{color: 'white'}}>
                            (ao depositar, re-logue em sua conta para atualizar o saldo)
                        </span>
                    </div>
                   
                }

                {isLoading && <LoadingMain/>}

                {errorDeposit && 
                    <span style={{color: 'white'}}>
                        {errorDeposit}
                    </span>
                }

            </div>
            
          
            
          </Box>
          
        </Modal>
        
      </div>
    )
}

export default ModalDeposit