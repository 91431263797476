export const RadarLoading = ({ text, statusResultado = null, color = "white" }) => {
    return (
        <>
            <div className="pulse">
                <span style={{ position: 'absolute', fontSize: !statusResultado ? '15px' : '20px', textAlign: 'center', color: color}}>
                    {text}
                </span>
                {/* Nova div para a barra branca */}
                <div className="vertical-bar"></div>
            </div>

            <style>{`
                .pulse {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
                
                .pulse:before {
                    animation: pulse-u9v30vmd 2s infinite;
                    display: block;
                    content: '';
                    height: 15px;
                    width: 15px;
                    border: 6.7px solid #115007;
                    border-radius: 50%;
                }

                /* Estilos para a nova barra branca */
                .vertical-bar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 10px; /* Largura da barra */
                    height: 100%;
                    background-color: #c0f8b8; /* Cor da barra branca */
                    animation: move-horizontal 1s linear infinite alternate; /* Animação da barra branca */
                    filter: blur(2px);
                    opacity: 0.5;
                }

                /* Animação para o pulse */
                @keyframes pulse-u9v30vmd {
                    0%, 20% {
                        border-width: 10px;
                    }
                    20%, 40% {
                        border-width: 20px;
                    }
                    60% {
                        border-color: #11500740;
                        border-width: 6.7px;
                        opacity: 1;
                        transform: scale(1);
                    }
                    100% {
                        border-color: #11500730;
                        opacity: 0;
                        transform: scale(2);
                    }
                }

                /* Animação para a barra branca */
                @keyframes move-horizontal {
                    0% {
                        left: 0; /* Começa do lado esquerdo */
                    }
                    100% {
                        left: calc(100% - 2px); /* Move para o lado direito */
                    }
                }
            `}</style>
        </>
    );
};
