import React, { createContext, useContext, useEffect, useState } from "react";

import { VerifSubDomain } from "../utils/verifSubdomain";
import instance from "../services/instance";
import { useAuth } from "./auth.context";

const WhitelabelContext = createContext({})
WhitelabelContext.displayName = 'WhitelabelContext'

const WhitelabelProvider = ({ children }) => {
    const [coresRoot, setCoresRoot] = useState([])
    const [clienteId, setClienteId] = useState()
    const [urlSacar, setUrlSacar] = useState('')
    const [urlDepositar, setUrlDepositar] = useState('')
    const [linkAfiliado, setLinkAfiliado] = useState()
    
    const [linkCadastro, setLinkCadastro] = useState('')
    const [linkAjuda, setLinkAjuda] = useState('')
    const [logoVertical, setLogoVertical] = useState()
    const [logoHorizontal, setLogoHorizontal] = useState()
    const [logo, setLogo] = useState('')
    const [nomeAfiliado, setNomeAfiliado] = useState('')

    const [liveBool, setLiveBool] = useState()
    const [liveLink, setLiveLink] = useState('')

    const [dominio, setDominio] = useState()
    

    const [textoAnalisando, setTextoAnalisando] = useState()

    const [primaryBackgroundHex, setPrimaryBackgroundHex] = useState('')

    const [loadedWhitelabel, setLoadedWhitelabel] = useState(false)
    const [listaGanhadores, setListaGanhadores] = useState([])

    const [rankingBool, setRankingBool] = useState()
    const [nomesBool, setNomesBool] = useState()

    const [credits, setcredits] = useState(0)

    const [verifySaldo, setVerifySaldo] = useState(0)

    const {authStatus} = useAuth()

    useEffect(() => {
      const response = VerifSubDomain()
      VerifySubDomain(response).then(() => {
        if (clienteId)
        setarCores().then(()=>{
            setTimeout(()=>{
                setLoadedWhitelabel(true)
            }, 1000)
        })
      })

      
    }, [])

    useEffect(()=>{
        const response = VerifSubDomain()
        console.log(response, 'subdominio')

        
          VerifySubDomain(response).then(() => {
            if(clienteId)
            setarCores().then(()=>{
              setTimeout(()=>{
                  setLoadedWhitelabel(true)
              }, 1000)
              
            })
          })
        

    }, [clienteId])

    const GetListaGanhadores = async () => {
      let response = await instance.get(`/Label/GetNomesVencedores`)
      try {
        setListaGanhadores(response)
        return response
      } catch (err) {
        throw err
      }
    }

    const GetListaRank = async () => {
      let response = await instance.get(`/Label/GetRank`)
      try {
        return response
      } catch (err) {
        throw err
      }
    }

    const VerifySubDomain = async (subdomain) => {
      if(subdomain != "Menu" && subdomain != "Perfil" && subdomain != "Games" && subdomain != "Wizard" && subdomain != "Academy" && subdomain != "Live" && subdomain != "SalaDeJogos"){
        try {
          let response = await instance.get(`/Label/VerifySubdomain?Subdominio=${subdomain}`)
          
          setClienteId(response.data.clienteId)
          localStorage.setItem('@ClientId:BetBuilder', response.data.clienteId)
          console.log(response.data)
        } catch (err) {
          //console.log("Erro ao verificar o subdominio no useWhitelabel: ", err)
          throw err
        }
      } else {
        return false
      }
        
    }

    

    const setarCores = async () => {
        let response = await instance.get(`/Label/GetWhiteLabel?ClienteId=${clienteId}`);
        // console.log(response)
        

       
        var myDynamicManifest = {
            "name": `${response.data.nomeAfiliado}`,
            "short_name": `${response.data.nomeAfiliado}`,
            "description": `Site de tips do ${response.data.nomeAfiliado}`,
            "start_url": `./${dominio}/`,
            "scope": ".",
            "display": "standalone",
            "background_color": "#000000",
            "theme_color": "#0f4a73",
            "icons": [{
              "src": `${response.data.favicon}`,
              "sizes": "256x256",
              "type": "image/png"
            }]
          }
      
          
          const stringManifest = JSON.stringify(myDynamicManifest);
          const blob = new Blob([stringManifest], {type: 'application/json'});
          const manifestURL = URL.createObjectURL(blob);
      
          
          document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

          document.title = `${response.data.nomeAfiliado}`;


          // Crie as meta tags Open Graph
          const ogTitle = document.createElement('meta');
          ogTitle.setAttribute('property', 'og:title');
        
          ogTitle.setAttribute('content', `${response.data.nomeAfiliado}`);
        
          

          const ogDescription = document.createElement('meta');
          ogDescription.setAttribute('property', 'og:description');
          ogDescription.setAttribute('content', `Site de tips do ${response.data.nomeAfiliado}`);

          const ogImage = document.createElement('meta');
          ogImage.setAttribute('property', 'og:image');
          ogImage.setAttribute('content', `${response.data.logo}`);

          // const ogURL = document.createElement('meta');
          // ogURL.setAttribute('property', 'og:url');
          // ogURL.setAttribute('content', 'URL da Página');

          // Adicione as meta tags ao cabeçalho
          document.head.appendChild(ogTitle);
          document.head.appendChild(ogDescription);
          document.head.appendChild(ogImage);
          // document.head.appendChild(ogURL);

          //Arrumar um jeito de carregar isso aqui so ao ler a url
          const metaTag = document.querySelector('meta[name="description"]');
          if (metaTag) {
            metaTag.setAttribute('content', `Site de tips do ${response.data.nomeAfiliado}`);
          }
    
          let link = document.querySelector("link[rel~='icon']");

          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = response.data.logo;

          const addAppleTouchIcon = () => {
            const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
            if (!appleTouchIcon) {
              const newAppleTouchIcon = document.createElement('link');
              newAppleTouchIcon.rel = 'apple-touch-icon';
              newAppleTouchIcon.href = response.data.logo; // Caminho para o novo ícone
      
              document.getElementsByTagName('head')[0].appendChild(newAppleTouchIcon);
            }
          };
      
          addAppleTouchIcon();


          const saveAppInfo = () => {
            const currentUrl = window.location.href;
            const appName = `${response.data.nomeAfiliado}`;
      
            localStorage.setItem('currentUrl', currentUrl);
            localStorage.setItem('appName', appName);
          };
      
          // Salva a URL atual e o nome do aplicativo ao adicionar o ícone no app mobile
          const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
          if (appleTouchIcon) {
            appleTouchIcon.addEventListener('load', saveAppInfo);
          }
      
          
                
            console.log(response.data)
            setPrimaryBackgroundHex(response.data.primaryBackground)
            setLogoVertical(response.data.logoVertical)
            setLogoHorizontal(response.data.logoHorizontal)
            setLogo(response.data.logo)
            setLinkAfiliado(response.data.linkAfiliado)
            setLinkCadastro(response.data.linkCadastro)
            setUrlDepositar(response.data.urlDepositar)
            setUrlSacar(response.data.urlSacar)
            setTextoAnalisando(response.data.textoAnalisando)
            setNomeAfiliado(response.data.nomeAfiliado)
            setLiveBool(response.data.liveBool)
            setLiveLink(response.data.liveLinnk)
            setRankingBool(response.data.rankingBool)
            setNomesBool(response.data.nomesBool)
            setVerifySaldo(response.data.verifySaldo)
            setCoresRoot(`
            :root {
                --Primary-color: ${response.data.primaryColor};
                --Secondary-color: ${response.data.secondaryColor};
    
                --Primary-background: ${response.data.primaryBackground};
                --Secondary-Background: ${response.data.secondaryBackground};
    
                --Icon: ${response.data.iconColor};
                --Button1: ${response.data.button1Color};
                --Button2: ${response.data.button2Color};
                --Primary-text: ${response.data.primaryTextColor};
                --Secondary-text: ${response.data.secondaryTextColor};
    
                --Toggle-color: ${response.data.toggleColor};
    
                --Primary-linear: ${response.data.primaryLinear};
                --Secondary-linear: ${response.data.secondaryLinear};
    
                --Transluced-background : ${response.data.translucedBackground};
    
                --Primary-font: ${response.data.primaryFont};
    
                --Background-image: url(${response.data.backgroundImage});
                --Background-image2: ${response.data.backgroundImage2};
    
                
                --Background-navBar: ${response.data.backgroundNavBar};
                --Background-sideBar: ${response.data.backgroundSidebar};

                --Matiz-background: ${response.data.matizBackground};
    
                --SideBar-width: 250px;
                --Header-height: 71px;
            }
        `)

        return () => {
          if (appleTouchIcon) {
            appleTouchIcon.removeEventListener('load', saveAppInfo);
          }
        };
        
}


useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = logo;


  }, [logo]);

  useEffect(() => {
    document.title = `${nomeAfiliado}`;
    
    
    // Crie as meta tags Open Graph
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
   
    ogTitle.setAttribute('content', `${nomeAfiliado}`);
  
    

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.setAttribute('content', `Site de tips do ${nomeAfiliado}`);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute('property', 'og:image');
    ogImage.setAttribute('content', `${logo}`);

    // const ogURL = document.createElement('meta');
    // ogURL.setAttribute('property', 'og:url');
    // ogURL.setAttribute('content', 'URL da Página');

    // Adicione as meta tags ao cabeçalho
    document.head.appendChild(ogTitle);
    document.head.appendChild(ogDescription);
    document.head.appendChild(ogImage);
    // document.head.appendChild(ogURL);

    //Arrumar um jeito de carregar isso aqui so ao ler a url
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', `Site de tips do ${nomeAfiliado}`);
    }

  }, [nomeAfiliado])

  useEffect(() => {
    const addAppleTouchIcon = () => {
      const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
      if (!appleTouchIcon) {
        const newAppleTouchIcon = document.createElement('link');
        newAppleTouchIcon.rel = 'apple-touch-icon';
        newAppleTouchIcon.href = logo; // Caminho para o novo ícone

        document.getElementsByTagName('head')[0].appendChild(newAppleTouchIcon);
      }
    };

    addAppleTouchIcon();
  }, [logo]);

  useEffect(() => {
    const saveAppInfo = () => {
      const currentUrl = window.location.href;
      const appName = `${nomeAfiliado}`;

      localStorage.setItem('currentUrl', currentUrl);
      localStorage.setItem('appName', appName);
    };

    // Salva a URL atual e o nome do aplicativo ao adicionar o ícone no app mobile
    const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
    if (appleTouchIcon) {
      appleTouchIcon.addEventListener('load', saveAppInfo);
    }

    return () => {
      if (appleTouchIcon) {
        appleTouchIcon.removeEventListener('load', saveAppInfo);
      }
    };
  }, [nomeAfiliado]);

    
    

    return (
        <WhitelabelContext.Provider value={{     
                coresRoot,
                setarCores,
                linkCadastro,
                clienteId,
                urlDepositar,
                urlSacar,
                linkAfiliado,
                primaryBackgroundHex,
                logo,
                logoHorizontal,
                logoVertical,
                loadedWhitelabel,
                linkAjuda,
                textoAnalisando,
                VerifySubDomain,
                setDominio, 
                dominio,
                listaGanhadores,
                GetListaGanhadores,
                GetListaRank,
                liveBool, liveLink,
                rankingBool,
                nomesBool,
                credits, setcredits,
                setVerifySaldo, verifySaldo
        }}>
            {children}
        </WhitelabelContext.Provider>
    )
}


export const useWhitelabel = () => {
    const context = useContext(WhitelabelContext)

    if(!context) {
        throw new Error("Erro ao usar o useWhitelabel")
    }

    return context;
}

export {WhitelabelContext, WhitelabelProvider}